export default {
  or: "oppure",
  name: "Nome",
  status: "Stato",
  back: "Indietro",
  id: "Id",
  sku: "Sku",
  oemSKU: "Oem Sku",
  description: "Descrizione",
  price: "Prezzo",
  fullPrice: "Prezzo di listino",
  orderPrice: "Prezzo ordine",
  stock: "Stock",
  code: "Codice",
  invoiced: "Fatturato",
  change: "Modifica",
  address: "Indirizzo",
  addressMissing: "Indirizzo mancante",
  creationDate: "Data di creazione",
  timeZone: "Fuso orario",
  languageCode: "Codice lingua",
  roles: "Ruoli",
  permissions: "Permessi",
  years: "anni",
  months: "mesi",
  days: "giorni",
  sales: "Vendite",
  dashboard: "Dashboard",
  salesNetwork: "Rete vendita",
  marketing: "Marketing",
  brandAssets: "Brand Assets",
  support: "Supporto",
  knowledgebase: "Knowledge Base",
  helpdesk: "Assistenza clienti",
  designSystem: "Design System",
  devMenu: "Development",
  actions: "Azioni",
  confirm: "Conferma",
  dismiss: "Annulla",
  create: "Crea",
  edit: "Modifica",
  save: "Salva",
  password: "Password",
  repeatPassword: "Ripeti password",
  passwordCriteria:
    "Richiesti 8 caratteri una lettera maiuscola, minuscola, un numero e un carattere speciale",
  search: "Cerca",
  reset: "Reset",
  group: "Gruppo",

  profile: "Profilo",
  company: "Azienda",
  accounts: "Account",
  userId: "Utenti",
  network: "Network",
  backToNetwork: "Torna alla rete di vendita",
  certifications: "Certificazioni",
  sellOut: "Ordini vendita",
  sellIn: "Ordini acquisto",
  pricelList: "Listino Prezzi",
  licenseKeys: "Licenze",
  email: "Email",
  noChildAccounts: "Non ci sono account in questa network",
  profileEdit: "Modifica Profilo",
  backToProfile: "Torna al profilo",
  userUpdated: "Dati aggiornati",
  userUpdateError: "Errore aggiornamento utente",
  dangerZone: "Danger Zone",
  deleteProfile: "Cancella profilo",
  deleteMe: "Cancellami",
  profileRemove: "Cancella il tuo profilo",
  profileRemoveDescription:
    "Vuoi cancellare definitivamente io tuo profilo? Non sarai più in grado di accedere.",

  companyEdit: "Modifica Azienda",
  backToCompany: "Torna all'azienda",
  companyAddress: "Indirizzo Azienda",

  priceLists: "Listini Prezzi",
  priceList: "Listino Prezzi",
  priceListName: "Nome listino Prezzi",
  priceListNew: "Nuovo Listino Prezzi",
  priceListBack: "Torna ai listini Prezzi",
  priceListsProduct: "Voce di listino",
  priceListsProductNew: "Nuova voce di listino",
  priceListsCreateError: "Errore nella creazione listino prezzi",
  priceListsFetchError: "Errore nel recupero listini prezzi",
  priceListsDeleteError: "Errore nella cancellazione listino prezzi",
  priceListsDeleteErrorInuse:
    "Errore nella cancellazione: listino prezzi in uso",
  priceListsUpdateError: "Errore nell'aggiornamento listino prezzi",
  priceListsUpdateSuccess: "Listino prezzi aggiornato",
  priceListsRemove: "Cancella Listino",
  priceListsRemoveDescription: "Vuoi cancellare il listino prezzi?",
  priceListsRemoveProduct: "Rimuovi prodotto",
  priceListsRemoveProductDescription:
    "Vuoi rimuovere il prodotto dal listino prezzi?",
  priceListAssociated: "Listino prezzi associato",
  priceListNotAssociated: "Nessun listino prezzi associato",
  priceListAssociate: "Associa listino prezzi",
  pricelistAssociatePropagation: "Rendi disponibile listino per account figli",
  fixed: "Fisso",
  percentage: "Percentuale",

  newAccountTitle: "Nuovo Account Partner",
  accountName: "Nome azienda",
  accountAdmin: "Amministratore",
  firstName: "Nome",
  lastName: "Cognome",
  createAsChild: "Crea come account figlio",
  accountRemove: "Cancella account",
  accountRemoveDescription: "Vuoi cancellare l'account?",
  searchAccount: "cerca azienda",
  parentAccount: "Account padre",
  accountUpdateSuccess: "Account aggiornato",
  notificationEnabled: "Notifiche via mail",
  notificationReceiverEmail: "Mail per notifiche account",

  legalName: "Ragione sociale",
  street: "Via",
  city: "Città",
  locality: "Provincia",
  region: "Regione",
  postalCode: "CAP",
  country: "Paese",
  countryCode: "Codice paese",
  vatId: "Codice fiscale / Numero di registro",
  accountGroup: "Tipo di account",

  licenses: "Licenze",
  license: "Licenza",
  serialKey: "Chiave seriale",
  seats: "Attivazioni",
  hasBeenActivated: "Attivo",
  licenseFetchError: "Errore nel recupero licenza",
  licensesFetchError: "Errore nel recupero licenze",
  clearSeatError: "Errore nella rimozione seat",
  clearSeatConfirm: "Vuoi sospendere i seat?",
  clearSeatTitle: "Sospensione Seat",
  licenseReports: "Report licenze",
  owner: "Proprietario",
  activationDate: "Data di attivazione",
  expirationDate: "Data di scadenza",
  maintenanceExpirationDate: "Data di scadenza manutenzione",
  maintenanceExp: "Scadenza manutenzione",
  deviceName: "Dispositivo",
  deviceUniqueId: "ID univoco",
  deviceDescription: "Descrizione",
  installedProduct: "Software installato",
  installedVersion: "Versione",
  active: "Attivo",
  suspended: "Sospeso",
  currentTrialStep: "Trial step",
  licenseHistory: "Storico Licenza",
  duration: "Durata",
  noExpiration: "Nessuna scadenza",
  backToLicenses: "Torna alle licenze",
  licenseNew: "Nuova licenza",
  quantity: "Quantità",
  generateSeparateKeys: "Genera chiavi separate",
  licenseCreateError: "Errore creazione licenza",
  licenseCreateSuccess: "Licenza creata",
  generateExtensions: "Aggiungi estensioni",
  type: "Tipo",
  trialLicense: "Trial",
  fullLicense: "Full",

  rulesRequired: "Campo richiesto",
  rulesEmail: "Inserisci una mail valida",
  rulesNumber: "Inserisci un numero",
  rulesMatchPassword: "Le password devono coincidere",

  signInTitle: "Accedi al tuo account",
  signInDescription: "Inserisci le tue credenziali per accedere",
  signInEmail: "Email",
  insertCode: "Inserisci codice",
  signInPassword: "Password",
  signInForgot: "Password dimenticata?",
  signInCta: "Accedi",
  signInLoginError: "Errore: username o password sbagliati",
  signInFetchProvidersError: "Errore nel recupero dei plugin di autenticazione",
  signInGoogle: "Accedi con Google",
  signInMicrosoft: "Accedi con Microsoft",
  signInApple: "Accedi con Apple",
  forgotTitle: "Recupera password",
  forgotDescription:
    "Inserisci la mail con cui ti sei registrato per recuperare la password",
  forgotBack: "Torna al login",
  forgotSubmit: "Recupera",
  forgotSuccess: "Codici di recupero inviati, controlla la tua mail",
  forgotError: "Errore nel recupero password, riprova più tardi",
  resetTitle: "Reimposta password",
  resetDescription: "Inserisci la nuova password",
  resetPassSuccess: "Password impostata",
  resetPassError: "Errore nell'impostazione della nuova password",
  resetCta: "Reimposta password",
  confirmMailSuccess: "Email confermata",
  confirmMailError: "Errore nella conferma mail",
  confirmTitle: "Email confermata",
  confirmDescription: "Inserisci la nuova password",
  confirmPassCta: "Conferma password",
  logout: "logout",

  mfaChoseProvider: "Scegli provider MFA",
  mfaProviders: "Autenticazione Multifactor",
  mfaDisableProvider: "Provider disabilitato",
  mfaDisableProviderError: "Errore nel disabilitare provider",
  mfaEnableProvider: "Provider abilitato",
  mfaEnableProviderError: "Errore nell'abilitare provider",
  mfaSelectProviderError: "Errore nel selezionare provider",
  mfaShowBackupCodes: "Visualizza codici di backup",
  mfaBackupCodes: "Codici di backup",
  mfaBackupCodesDescription:
    "Questo elenco di codici monouso ti aiuterà a reimpostare l'autenticazione a più fattori. Se sei bloccato fuori dal tuo account perché non riesci ad accedere alla tua e-mail o al tuo telefono, puoi utilizzare un codice di backup per reimpostare l'autenticazione e tornare al tuo account. Copia questi codici e conservali in un luogo sicuro.",
  mfaEmailMessage: "Ti è stato inviato un messaggio, controlla la tua posta",
  mfaAuthenticatorMessage: "Scansiona il codice QR con un authenticator",
  mfaInsertCode: "Inserisci il codice",
  disable: "Disabilita",
  enable: "Abilita",

  products: "Prodotti",
  product: "Prodotto",
  backToProducts: "Torna ai prodotti",
  isLicense: "Licenza",
  licensePrefix: "Prefisso licenza",
  licenseDuration: "Durata licenza",
  licenseActivationRule: "Rinnovo licenza",
  islicenseUpgradeRight: "Rinnovabile",
  isLicenseTrial: "Licenza di prova",
  firstnextmonth: "primo giorno del mese",
  productType: "Tipo di prodotto",
  productFeatures: "Features prodotto",
  environmentType: "Tipo Ambiente",
  discountType: "Tipo di sconto",
  discountAmount: "Ammontare sconto",
  customPrice: "Prezzo personalizzato",
  customPriceDiscountAmount: "Ammontare sconto",
  productItemDateFrom: "Valido da",
  productItemDateTo: "Valido fino a",
  currency: "Valuta",
  licenseMaxVersion: "Massima versione prodotto",
  licenseDurationYears: "Durata anni",
  licenseDurationMonths: "Durata mesi",
  licenseDurationDays: "Durata giorni",
  tenants: "Tenants",
  licenseMaintenance: "Durata manutenzione",
  maintenanceDurationYears: "Manutenzione anni",
  maintenanceDurationMonths: "Manutenzione mesi",
  maintenanceDurationDays: "Manutenzione giorni",
  extendedProductId: "Estensione",
  subscriptionId: "Subscription",
  licenseUpdateSuccess: "Licenza aggiornata",
  licenseUpdateError: "Errore aggiornamento licenza",
  productEdit: "Modifica prodotto",
  productCreate: "Crea prodotto",
  productDeleteSuccess: "Prodotto cancellato",
  productDeleteError: "Errore cancellazione prodotto",
  productEditError: "Errore modifica prodotto",
  productEditSuccess: "Prodotto modificato",
  productCreateError: "Errore creazione prodotto",
  productCreateSuccess: "Prodotto creato",
  productRemove: "Cancella prodotto",
  productRemoveDescription: "Il prodotto verrà cancellato definitivamente",

  order: "Ordine",
  orders: "Ordini",
  vendor: "Venditore",
  buyer: "Acquirente",
  backToOrders: "Torna agli Ordini",
  createdBy: "Creato da",
  paid: "Pagato",
  orderHistory: "Storico ordine",
  orderAttachments: "Allegati",
  source: "Origine",
  updateDate: "Data aggiornamento",
  orderItems: "Dettaglio ordine",
  newSellOut: "Nuovo ordine di vendita",
  confirmSellOutTitle: "Conferma creazione ordine",
  confirmSellOutDescription:
    "Confermi di voler procedere? I prezzi non potranno essere modificati in seguito.",
  addProduct: "Seleziona prodotti da aggiungere",
  orderCreateError: "Errore creazione ordine",
  total: "Totale",
  newSellIn: "Nuovo ordine di acquisto",
  unknown: "Sconosciuto",
  pending: "Da approvare",
  approved: "Approvato",
  canceled: "Non approvato",
  revoked: "Revocato",
  approve: "Approva",
  deny: "Rigetta",
  cancel: "Rigetta",
  revoke: "Revoca",
  orderUpdateSuccess: "Ordine agiornato",
  orderUpdateError: "Errore agiornamento ordine",
  orderRevokeError:
    "Impossibile revocare l’ordine. Stock insufficiente o giá utilizzato.",
  refCode: "Riferimento ordine",
  notes: "Note",
  orderTotal: "Totale ordine",
  filterCreationDate: "Filtra per data di creazione",
  filterActivationDate: "Filtra per data di attivazione",
  filterExpirationDate: "Filtra per data di scadenza",
  filterMaintenanceDate: "Filtra per scadenza manutenzione",
  filterParent: "Filtra per parent account",
  downloadReport: "Scarica report",
  downloadReportError: "Errore download report",
  reports: "Reports",

  clientRef: "Riferimento cliente",

  ordersFetchError: "Errore nel recupero ordini",
  productsFetchError: "Errore nel recupero prodotti",
  productsTypesFetchError: "Errore nel recupero tipologie prodotti",
  productsTenantsFetchError: "Errore nel recupero tenants",
  productsTypes: "Tipologie prodotti",
  productTypeDeleteSuccess: "Tipologia cancellata",
  productTypeDeleteError: "Errore nella cancellazione tipologia",
  productTypeEdit: "Modifica tipologia prodotto",
  accountsFetchError: "Errore nel recupero accounts",
  accountsGetError: "Errore nel recupero account",
  accountsPutError: "Errore aggiornamento account",
  accountsAssociatedListError: "Errore nel recupero listino prezzi",
  usersFetchError: "Errore nel recupero utenti",
  countriesFetchError: "Errore nel recupero lista nazioni",
  currenciesFetchError: "Errore nel recupero lista valute",
  accountGroupsFetchError: "Errore nel recupero gruppi account",
  rolesFetchError: "Errore nel recupero ruoli",
  stocksFetchError: "Errore nel recupero stock",
  insufficientStockError: "Stock insufficienti per i prodotti:",
  missing: "mancanti",
  pleasePurchase: "Per favore provvedere ad eseguire un ordine d'aquisto.",
  notAvailableProducts:
    "Prodotti non presenti in stock. Per favore eseguire un ordine di acquisto.",

  newUser: "Nuovo utente",
  backToAccount: "Torna all'account",
  role: "Ruolo",
  newUserError: "Errore creazione utente",
  newUserSuccess: "Nuovo utente creato",
  userRemove: "Cancella Utente",
  userRemoveRemoveDescription: "Vuoi cancellare definitivamente l'utente?",
  userRemoveYourselfDescription:
    "Vuoi cacellare il tuo profilo? Non potrai più accedere",
  userRemoved: "Utente rimosso",
  userRemoveError: "Errore cancellazione utente",

  certificatesFetchError: "Errore nel recupero delle certificazioni",
  certificatesUpdateError: "Errore nell'aggiornamento certificazione",
  certificatesDeletionError: "Error nella cancellazione certificazione",
  certificateRemove: "Rimouvi Certificazione",
  certificateRemoveRemoveDescription: "Vuoi rimuovere la certificazione?",
  backToCertifications: "Lista Certificazioni",
  certificate: "Certificato: ",
  certificationCreationDate: "Data Creazione Certificato",
  certificationExpirationDate: "Data Scedenza Certificato",
  newUserCertification: "Nuovo Certificato Utente",
  newAccountCertification: "Nuovo Certificato Account",
  addNewUserCertification: "Certificato Aggiunto",
  accountCertificate: "Certificazioni Account",
  accountCertificatesList: "Lista Certificati Account",
  certificateAccountRemove: "Rimozione Certificato Account",
  certificateAccountRemoveRemoveDescription:
    "Vuoi rimuovere il certificato dall'account?",
  certificateName: "Nome Certificato",
  certificates: "Certificato",
  renewCertificate: "Rinnova",
  downloadCertificate: "Scarica Certificato",
  createAccountCertification: "Certificato Aggiunto",
  deleteAccountCertification: "Certificato Eliminato ",
  deleteUserCertification: "Certificato Eliminato ",
  updateUserCertificationNotes: "Certificato Aggiornato",
  renewUserCertification: "Certificato Rinnovato",
  duplicateCertification: "Certificazione già assegnata all'utente",
  duplicateAccountCertification: "Certificazione già assegnata all'account",
  validateUserCertification: "Certificato Utente Valido",
  validationCode: "ID Certificato",
  validationPlaceholder: "es. ABC123",
  validateTitle: "Verifica Certificato",
  validateDescription: "Inserisci un ID Certificato da verificare",
  validCertificate: "Certificato valido",
  validationCodeNotFound: "Certificato invalido",
  validateError: "Errore",
  expiredCertificate: "Certificato scaduto",
  validateCertificateGeneralError:
    "Errore durante la validazione del certificato, si prega di riprovare",
  validate: "Verifica",
  validationPage: "valida certificato",
  userCertifications: "Certificazioni Utenti",
  renewCertificateError: "Errore durante il rinnovo certificato",
  guestCertificate: "Certificato Guest",
  valid: "Valido",

  user: "Utente",
  users: "Utenti",
  idCertificate: "ID Certificato",

  ODM: "ODM",
  OEM: "OEM",
  OPCO: "OPCO",
  DISTRIBUTOR: "Distributore",
  RESELLER_PLATINUM: "Rivenditore Platinum",
  RESELLER_GOLD: "Rivenditore Gold",
  RESELLER_SILVER: "Rivenditore Silver",
  BUYER: "Cliente",

  user_pending: "Da confermare",
  user_active: "Attivo",
  user_suspended: "Sospeso",
  New: "Nuovo",
  Active: "Attivo",
  Pending: "In sospeso",
  Deleted: "Cancellato",
  Approved: "Approvato",
  Refused: "Rifiutato",
  Duplicated: "Duplicato",

  "YYYY/MM/DD": "DD/MM/YYYY",
  AccountAdmin: "Amministratore",
  Basic: "Utente regolare",
  SuperAdmin: "Super Admin",

  accountStatsError: "Errore nel recupero statistiche",
  newSubscribers: "Account registrati",
  chosePeriod: "Scegli un periodo di riferimento",
  currentPeriod: "Periodo corrente",
  previousPeriod: "Periodo precendente",
  includePrevious: "Includi periodo precendente",
  from: "dal",
  to: "al",
  week: "settimana",
  includeChildren: "Includi account figli",
  onlyMyLicenses: "Visualizza solo le mie licenze",
  onlyMyOrders: "Visualizza solo i miei ordini",
  onlyMyDeals: "Visualizza solo i miei deal",
  newLicenses: "Licenze generate",
  newDeals: "Nuovi deals",
  licenseKeyCopied: "Chiave di licenza copiata negli appunti",
  orderReference: "Ordine di riferimento",

  deals: "Deal registration",
  deal: "Deal",
  dealsFetchError: "Errore nel recupero deal",
  dealsHistoryFetchError: "Errore nel recupero storico deal",
  dealUpdateSuccess: "Deal aggiornato con successo",
  dealUpdateError: "Errore aggiornamento Deal",
  estimatedCloseDate: "Stima chiusura Deal",
  eBidStatus: "Stato offerta",
  protectionStatus: "Stato protezione",
  dealStatus: "Stato Deal",
  backToDeals: "Torna ai deal",
  prospect: "Potenziale cliente",
  companyName: "Nome azienda",
  prospectName: "Nome cliente",
  webSite: "Sito web",
  dealItems: "Dettaglio Deal",
  expired: "Scaduto",
  startDate: "Data inizio",
  closureChance: "Probabilità di chiusura",
  phone: "Telefono",
  Trial: "Trial",
  Demo: "Demo",
  Negotiation: "Negoziazione",
  Won: "Concluso con successo",
  Lost: "Non concluso",
  dealHistory: "Storico Deal",
  eBid: "Offerta",
  eBidValue: "Proposta di offerta",
  eBidApprovedValue: "Offerta approvata",
  expiration: "Scadenza",
  markAsDuplicated: "Segna come duplicato",
  update: "Aggiorna",
  newDeal: "Nuovo Deal",
  dealProtection: "Protezione deal",
  dealDescription: "Descrizione Deal",
  updateOrigin: "Origine Aggiornamento",
  Unknown: "Sconosciuto",
  Creation: "Iniziale",
  UpdatedByCreator: "Modificato dal venditore",
  UpdatedByApprover: "Modificato dal responsabile",
  System: "Automatico",

  stocks: "Stock",
  noAccountError: "Nessun account selezionato",
  airServerRequired: "AirServer richiesto",
  onPremEnvFetchError: "Errore nel recupero ambienti On Premise",
  onPremEnvDeleteSuccess: "Ambiente On Premise rimosso",
  onPremEnvDeleteError: "Errore nella rimozione ambiente On Premise",
  onPremEnvSaveSuccess: "Errore salvataggio ambiente On Premise",
  onPremEnvSaveError: "Ambiente On Premise salvato",
  onPremEnv: "Ambiente On Premise",
  customerName: "Nome Ambiente",
  customerEnvironmentId: "Id Ambiente",
  onPremEnvRemove: "Rimuovi Ambiente",
  onPremEnvRemoveDescription:
    "Cancella definitivamente questo ambiente On Premise",
  backToOnPremEnv: "Torna agli Ambienti",
  exportLicenseOnPrem: "Esporta licenza verso ambiente On Premise",
  hasBeenExported: "Esportata",
  licenseExportError: "Errore nell'esportazione licenza",
  productFeatureDeleteSuccess: "Feature cancellata",
  productFeatureDeleteError: "Errore nella cancellazione feature",
  productFeatureSaveSuccess: "Feature salvata",
  productFeatureSaveError: "Errore nel salvataggio feature",
  created: "Creata",
  activated: "Attivata",
  licenseExp: "Scandenza",
  inactive: "Inattiva",
  noDate: "(no data)",
  yes: "Si",
  no: "No",
  perpetual: "Perpetua",
  extendedSku: "SKU Esteso",
  notExpired: "Valida",
  totalCreated: "Create",
  totalActive: "Attive",
  totalExpired: "Scadute",
  totalMaintenanceExpired: "Manutenzione scaduta",
  totalNearMaintenanceExpiration: "Manutenzione in scadenza",
  totalSellIn: "Acquisti",
  totalSellOut: "Vendite",
  totalSellInPrice: "Totale Acquisti",
  totalSellOutPrice: "Totale Vendite",
  intervalWeek: "Ultima settimana",
  intervalThisMonth: "Questo mese",
  intervalLastMonth: "Mese precedente",
  intervalThisQuarter: "Questo trimestre",
  intervalLastQuarter: "Trimestre precedente",
  intervalThisYear: "Questo anno",
  intervalLastYear: "Anno precedente",
  intervalAllTime: "Da sempre",
  intervalCustom: "Date personalizzate",
  nodata: "Nessun dato da visualizzare.",
  noProduct: "Nessun prodotto",
  totalSeats: "Totale attivazioni",
  ofWhich: "di cui",
  used: "usate",
  files: "Files",
  filesFetchError: "Errore nel recupero file",
  size: "Dimensioni",
  uploadBy: "Caricato da",
  uploadDate: "Data",
  fileRemoveDescription: "Vuoi rimuovere il file?",
  fileRemove: "Cancella file",
  fileRemoveSuccess: "File cancellato",
  fileRemoveError: "Errore nella cancellazione file",
  accountFiles: "Account Files",
  fileUploadSuccess: "File caricato",
  fileUploadError: "Errore caricamento file",
  fileEditSuccess: "File aggiornato",
  fileEditError: "Errore modifica file",
  fileDownloadError: "Errore downloading file",
  download: "Download",
  filterUploadDate: "Filtra per data di caricamento",
  fileSizeError: "Dimensioni file non valide",
};
