<template>
  <div class="va-card px-4 py-5 sm:col-span-2 mt-6">
    <h3 class="va-title-s">{{ t("orderItems") }}</h3>
    <dd class="mt-1 text-sm text-gray-900">
      <div class="overflow-scroll">
        <table class="min-w-full divide-y divide-gray-300 mt-4">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {{ t("product") }}
              </th>
              <!--              <th-->
              <!--                scope="col"-->
              <!--                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"-->
              <!--              >-->
              <!--                {{ t("description") }}-->
              <!--              </th>-->
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {{ t("priceList") }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
              >
                {{ t("quantity") }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
              >
                {{ t("fullPrice") }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
              >
                {{ t("orderPrice") }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
              >
                {{ t("total") }}
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="(item, index) in items" :key="index">
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                {{ item.name }}
                <div
                  class="text-ellipsis overflow-hidden max-w-md text-sm text-gray-500"
                >
                  {{ item.sku }}
                </div>
              </td>
              <!--              <td class="whitespace-nowrap px-3 py-4 text-sm">-->
              <!--                <div class="text-ellipsis overflow-hidden max-w-xs">-->
              <!--                  {{ item.description }}-->
              <!--                </div>-->
              <!--              </td>-->
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                <div class="text-ellipsis overflow-hidden max-w-xs">
                  {{ item.priceListName }}
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                <div class="text-right">
                  {{ item.quantity }}
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                <div class="text-right text-gray-500 line-through">
                  {{ useFormatCurrency(item.priceListPrice, item.currency) }}
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                <div class="text-right">
                  {{ useFormatCurrency(item.price, item.currency) }}
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                <div class="text-right">
                  {{
                    useFormatCurrency(item.price * item.quantity, item.currency)
                  }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex flex-row-reverse">
        <h3 class="va-title-s mt-4">
          {{ t("orderTotal") }}:
          {{ useFormatCurrency(total, currency) }}
        </h3>
      </div>
    </dd>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { t } from "@/plugins/i18n";
import { useFormatCurrency } from "@/composables/formatters";
import { useMeta } from "vue-meta";
import { OrderItem } from "@/models/orderItem.interface";
import { CurrencyData } from "@/models/currencyData.interface";

useMeta({
  title: "Orders",
});
defineProps({
  items: { type: Array as PropType<OrderItem[]>, required: true },
  total: { type: Number, required: false, default: 0 },
  currency: { type: Object as PropType<CurrencyData>, required: true },
});
</script>
