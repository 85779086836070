import axios, { AxiosRequestConfig } from "axios";
import { openUrls } from "@/services/configService";
import logger from "@/services/loggerService";
import { isAuthDataExpired } from "@/services/authService";
import tenantService from "@/services/tenantService";
import * as configService from "@/services/configService";
import { useAuthStore } from "@/stores/auth";

const _requestHandler = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  if (config.headers) {
    config.headers["tenant_code"] = tenantService.getTenantCode();
    config.headers["Label"] = tenantService.getTenant();
    config.headers["Product"] = configService.applicationName;
    if (
      config.url &&
      config.headers &&
      !config.headers.Authorization &&
      !openUrls.includes(config.url)
    ) {
      const authStore = useAuthStore();
      if (authStore.authData && authStore.authData.token) {
        if (isAuthDataExpired(authStore.authData)) {
          try {
            await authStore.refreshToken();
          } catch (err) {
            await authStore.logout();
          }
        }
        if (config.headers.Authorization !== null) {
          config.headers[
            "authorization"
          ] = `Bearer ${authStore.authData.token}`;
        } else {
          delete config.headers.Authorization;
        }
      }
    }
  }
  return config;
};

export default function start(): void {
  axios.interceptors.request.use(
    (request) => _requestHandler(request),
    (error) => {
      logger.error(error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const authStore = useAuthStore();
      if (
        error.response?.status === 401 &&
        !error.request?.responseURL.includes("/api/auth")
      ) {
        authStore.logout();
      }
      logger.error(error);
      return Promise.reject(error);
    }
  );
}
