<template>
  <div class="va-card px-4 py-5 sm:col-span-2 mt-6">
    <h3 class="va-title-s">{{ t("orderHistory") }}</h3>
    <dd class="mt-1 text-sm text-gray-900">
      <table class="min-w-full divide-y divide-gray-300 mt-4">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("source") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("status") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("creationDate") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("updateDate") }}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="(detail, index) in details" :key="index">
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ detail.source }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ detail.status }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ formatDate(detail.creationDate) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ formatDate(detail.updateDate) }}
            </td>
          </tr>
        </tbody>
      </table>
    </dd>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { t } from "@/plugins/i18n";
import { useFormatDate } from "@/composables/formatters";
import { useMeta } from "vue-meta";
import { OrderDetails } from "@/models/orderDetails.interface";

useMeta({
  title: "Orders",
});
defineProps({
  details: {
    type: Array as PropType<OrderDetails[]>,
    required: true,
  },
});

const formatDate = useFormatDate;
</script>
