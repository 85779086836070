<template>
  <div>
    <va-button :disabled="disabled" @click="openEditDialog(fileItem)">
      <i class="rm rm-ico-edit" />
    </va-button>

    <va-dialog v-model="editDialog">
      <a-form
        :model="formState"
        :validate-messages="validateMessages"
        @finish="onEditFile"
      >
        <a-form-item
          :name="['fileName']"
          :label="t('name')"
          :rules="[{ required: true }]"
        >
          <va-input v-model="formState.fileName" :placeholder="t('name')" />
        </a-form-item>

        <a-form-item
          :name="['fileNote']"
          :label="t('notes')"
          :rules="[{ max: 255 }]"
        >
          <va-input
            v-model="formState.fileNote"
            :placeholder="t('notes')"
            type="textarea"
          />
        </a-form-item>
        <a-form-item>
          <va-button html-type="submit" class="float-right">
            {{ t("save") }}
          </va-button>
          <va-button class="float-right mr-4" @click="editDialog = false">
            {{ t("dismiss") }}
          </va-button>
        </a-form-item>
      </a-form>
    </va-dialog>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { FileDownload } from "@/models/fileDownload.interface";
import { t } from "@/plugins/i18n";
import { useFileDownloadsStore } from "@/stores/fileDownloads";
import VaButton from "@/components/molecules/VaButton.vue";
import { cloneDeep } from "lodash";
import VaDialog from "@/components/molecules/VaDialog.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { useValidationMessages } from "@/composables/validation";

defineProps({
  fileItem: {
    type: Object as PropType<FileDownload>,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["update"]);

const filesStore = useFileDownloadsStore();
const validateMessages = useValidationMessages();

const editDialog = ref(false);
const formState = ref({} as FileDownload);

const openEditDialog = (file: FileDownload) => {
  formState.value = cloneDeep(file);
  editDialog.value = true;
};

const onEditFile = async () => {
  await filesStore.edit(formState.value);
  emit("update", formState.value);
  editDialog.value = false;
};
</script>
