<template>
  <a-range-picker
    v-model:value="dates"
    :placeholder="placeholder"
    :disabled="disabled"
    :format="t('YYYY/MM/DD')"
    @change="onchange"
  >
    <template #suffixIcon>
      <i class="rm rm-ico-calendar" />
    </template>
    <template #separator>
      <i class="rm rm-ico-arrow-right ant-picker-separator" />
    </template>
  </a-range-picker>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { useI18n } from "vue-i18n";

//need to fix antd bad dayJs import: https://github.com/ant-design/ant-design/issues/26190
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { FilterDateRange } from "@/models/listable.interface";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: Object as PropType<FilterDateRange>,
    required: false,
    default: undefined,
  },
  placeholder: { type: String, required: false, default: "" },
  disabled: { type: Boolean, required: false, default: false },
  // dayTarget: { type: String, required: false, default: "now" },
});

const emit = defineEmits(["change", "update:modelValue"]);

const dates = computed(() => {
  return [
    props.modelValue?.fromDate ? dayjs(props.modelValue.fromDate) : undefined,
    props.modelValue?.toDate ? dayjs(props.modelValue.toDate) : undefined,
  ];
});

const onchange = (value: string[]) => {
  let from = new Date(value[0]);
  let to = new Date(value[1]);
  from.setHours(0, 0, 0, 0);
  to.setHours(23, 59, 59, 999);
  const res = {
    field: props.modelValue?.field || "",
    fromDate: from,
    toDate: to,
  };

  emit("update:modelValue", res);
  emit("change", res);
};
</script>
<style lang="scss" scoped>
.ant-picker {
  @apply shadow-sm  rounded-md;
}
</style>
