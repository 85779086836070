import axios from "axios";
import * as configService from "@/services/configService";
import {
  SearchOptions,
  searchOptionsSerialize,
} from "@/models/listable.interface";
import FileModel, {
  FileDownload,
  tableConfig,
} from "@/models/fileDownload.interface";
import { Buffer } from "buffer";

export async function search(
  options: SearchOptions
): Promise<{ items: FileDownload[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.filesSearchUrl, opt);
  return {
    items: res.data.items.map(FileModel.deserialize),
    count: res.data.count,
  };
}

export async function uploadFile(
  file: File,
  fileCategory = "document",
  notes = "",
  parentAccountId: string | undefined
): Promise<string> {
  let accountFileId = "";
  const opt = {
    fileCategory: fileCategory,
    fileName: file.name,
    fileSizeInBytes: file.size,
    fileNote: notes,
    // fileHash: null,
    parentAccountId: parentAccountId,
  };
  const res = await axios.post(configService.filesUploadSessionUrl, opt);
  if (res.data.uploadUrl) {
    const fileData = Buffer.from(await file.arrayBuffer());
    await axios.put(res.data.uploadUrl, fileData, {
      headers: { ...res.data.headers, Authorization: null },
    });
    const commitOpt = {
      accountFileId: res.data.accountFileId,
      uploadSessionId: res.data.uploadSessionId,
      parentAccountId: parentAccountId,
    };
    await axios.post(
      configService.filesUploadSessionUrl + "/commit",
      commitOpt
    );
    accountFileId = res.data.accountFileId;
  }
  return accountFileId;
}

export async function attach(
  orderId: string,
  fileId: string
): Promise<FileDownload> {
  const res = await axios.post(
    `${configService.ordersUrl}/${orderId}/attachments`,
    {
      Attachments: [fileId],
    }
  );
  return FileModel.deserialize(res.data);
}

export async function deleteFile(id: string) {
  return await axios.delete(`${configService.filesUrl}/${id}`);
}

export async function editFile(id: string, name: string, notes: string) {
  return await axios.put(`${configService.filesUrl}/${id}`, {
    fileName: name,
    fileNote: notes,
  });
}

export async function downloadFile(id: string) {
  const res = await axios.get(`${configService.filesUrl}/${id}/download`);

  fetch(res.data.downloadUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${res.data.fileName}.${res.data.fileType}`;
      link.click();
      document.body.removeChild(link);
    });
}
