<template>
  <div v-if="true" class="va-card px-4 py-5 sm:col-span-2 mt-6">
    <div class="flex justify-between">
      <h3 class="va-title-s">{{ t("orderAttachments") }}</h3>
      <va-button :loading="ordersStore.isLoading" @click="uploader.click()">
        <i v-if="!ordersStore.isLoading" class="rm rm-ico-e-add" />
        <input ref="uploader" type="file" class="hidden" @change="uploadFile" />
      </va-button>
    </div>
    <dd class="mt-1 text-sm text-gray-900">
      <table class="min-w-full divide-y divide-gray-300 mt-4">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("name") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("type") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("size") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("notes") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("uploadBy") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("updateDate") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right"
            >
              {{ t("actions") }}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="(attachment, index) in order.attachments" :key="index">
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ attachment.fileName }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ attachment.fileType }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ formatBytes(attachment.fileSize) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ attachment.fileNote }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ attachment.uploadedByAccountName }}
              <div class="text-xs text-gray-500">
                {{ attachment.uploadedByUserFullName }}
              </div>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ formatDate(attachment.uploadDate) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              <div class="flex justify-end">
                <va-delete-button
                  class="mr-2"
                  :disabled="ordersStore.isLoading || filesStore.isLoading"
                  :confirm-text="t('fileRemoveDescription')"
                  :confirm-title="t('fileRemove')"
                  @click:confirm="
                    ordersStore.deleteAttachment(attachment.accountFileId)
                  "
                />
                <file-edit-btn-dialog
                  class="mr-2"
                  :file-item="attachment"
                  :disabled="ordersStore.isLoading || filesStore.isLoading"
                  @update="onUpdateAttachment"
                />
                <va-button
                  :disabled="ordersStore.isLoading || filesStore.isLoading"
                  @click="filesStore.download(attachment.accountFileId)"
                >
                  {{ t("download") }}
                </va-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </dd>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { t } from "@/plugins/i18n";
import { useFormatBytes, useFormatDate } from "@/composables/formatters";
import { useOrdersStore } from "@/stores/orders";
import { useMeta } from "vue-meta";
import { FileDownload } from "@/models/fileDownload.interface";
import VaButton from "@/components/molecules/VaButton.vue";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import { useFileDownloadsStore } from "@/stores/fileDownloads";
import FileEditBtnDialog from "@/components/organisms/FileEditBtnDialog.vue";

useMeta({
  title: "Orders",
});
defineProps({
  sellIn: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const ordersStore = useOrdersStore();
const filesStore = useFileDownloadsStore();
const formatDate = useFormatDate;
const formatBytes = useFormatBytes;

const uploader = ref();
const order = computed(() => ordersStore.currentOrder);

const uploadFile = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target?.files && target.files[0] && order.value) {
    const file = target.files[0];
    ordersStore.attach(file, order.value.id);
  }
};

const onUpdateAttachment = (file: FileDownload) => {
  if (order.value) {
    order.value.attachments = order.value.attachments.map(
      (att: FileDownload) => {
        return att.accountFileId == file.accountFileId ? file : att;
      }
    );
  }
};
</script>
