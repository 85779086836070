import { TableConfig } from "@/models/listable.interface";

export interface FileDownload {
  accountFileId: string;
  fileCategory: number;
  ownerAccountId: string;
  ownerAccountName: string;
  fileName: string;
  fileType: string;
  fileSize: number;
  fileHash: string;
  fileNote: string;
  uploadedByAccountId: string;
  uploadedByAccountName: string;
  uploadedByUserFullName: string;
  uploadDate: Date;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): FileDownload {
    return {
      accountFileId: input.accountFileId || "",
      fileCategory: input.fileCategory || 0,
      ownerAccountId: input.ownerAccountId || "",
      ownerAccountName: input.ownerAccountName || "",
      fileName: input.fileName || "",
      fileType: input.fileType || "",
      fileSize: input.fileSizeInBytes || 0,
      fileHash: input.fileHash || "",
      fileNote: input.fileNote || "",
      uploadedByAccountId: input.uploadedByAccountId || "",
      uploadedByAccountName: input.uploadedByAccountName || "",
      uploadedByUserFullName: input.uploadedByUserFullName || "",
      uploadDate: new Date(input.uploadDate),
    };
  },
  serialize(input: FileDownload): unknown {
    return {
      accountFileId: input.accountFileId,
      fileCategory: input.fileCategory,
      ownerAccountId: input.ownerAccountId,
      ownerAccountName: input.ownerAccountName,
      fileName: input.fileName,
      fileType: input.fileType,
      fileSizeInBytes: input.fileSize,
      fileHash: input.fileHash,
      fileNote: input.fileNote,
      uploadedByAccountId: input.uploadedByAccountId,
      uploadedByAccountName: input.uploadedByAccountName,
      uploadedByUserFullName: input.uploadedByUserFullName,
      uploadDate: input.uploadDate.getTime(),
    };
  },
};

export const tableConfig = {
  fileName: {
    dtoName: "fileName",
    sortable: true,
    filterable: true,
    label: "name",
  },
  fileType: {
    dtoName: "fileType",
    sortable: false,
    filterable: true,
    label: "type",
  },
  fileSize: {
    dtoName: "fileSizeInBytes",
    sortable: false,
    filterable: false,
    label: "size",
  },
  fileNote: {
    dtoName: "fileNote",
    sortable: false,
    filterable: false,
    label: "notes",
  },
  uploadedByAccountName: {
    dtoName: "uploadedByAccountName",
    sortable: false,
    filterable: true,
    label: "uploadBy",
  },
  uploadDate: {
    dtoName: "uploadDate",
    sortable: true,
    filterable: false,
    label: "uploadDate",
  },
  ownerAccountName: {
    dtoName: "ownerAccountName",
    sortable: true,
    filterable: true,
    label: "owner",
  },
  actions: {
    dtoName: "accountFileId",
    sortable: false,
    filterable: false,
    label: "actions",
  },
} as TableConfig;
